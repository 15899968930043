import React from "react";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/style.css";
import "../../assets/css/custom.css";
import "../../assets/css/responsive.css";

const Footer = () => {
  return (
    <>
      <footer class="new_footer_area">
        <div class="footer_bottom">
          <div class="container">
            <div class="row">
              <div class="col-lg-6 col-sm-12">
                <p class="mb-0 f_400 text-white">
                  © ChembioAI 2024 All rights reserved.
                </p>
              </div>
              <div class="col-lg-6 col-sm-12 ">
                <div className="fright-con text-white">
                  <a
                    style={{ marginRight: "15px" }}
                    href="https://www.facebook.com/profile.php?id=61551698095605"
                    className="fright mr-3 text-white"
                  >
                    <i class="bi bi-facebook"></i>
                  </a>
                  <a
                    style={{ marginRight: "15px" }}
                    href="https://chat.whatsapp.com/FmrqjhNAZsgECvLsJcR0fw"
                    className="fright text-white"
                  >
                    <i class="bi bi-whatsapp"></i>
                  </a>
                  <a
                    style={{ marginRight: "15px" }}
                    href="https://www.instagram.com/chembioai"
                    className="fright text-white"
                  >
                    <i class="bi bi-instagram"></i>
                  </a>
                  <a
                    style={{ marginRight: "15px" }}
                    href="https://www.linkedin.com/company/chembio-ai"
                    className="fright mr-3 text-white"
                  >
                    <i class="bi bi-linkedin"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
