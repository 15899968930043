import React from 'react'
import './Fellowship.css'
const Fellowship = () => {
    return (
        <>
            <section id="services" className="seo_features_one sec_pad services bg-black">
                <div className="container section-title" data-aos="fade-up">
                    <h2>Fellowship Program</h2>
                </div>
                <div className="container">
                    <div className="row g-5">
                        <div className="row">
                            <div className="col-lg-12 d-flex align-items-center">
                                <div className="seo_features_content m-auto">
                                    <p style={{ maxWidth: '700px' }} className='description text-center mb-4'>
                                        This fellowship program is tailored for aspiring research scholars and PhD students. Dive deep into the realms of AI, bioinformatics, computational biology, and drug discovery as you embark on a transformative research journey.                                </p>

                                    <p style={{ maxWidth: '700px' }} className='description text-center mb-4'>
                                        As a fellow, you will not only engage in cutting-edge research but also receive a stipend to support your academic endeavors.                                </p>

                                    <p style={{ maxWidth: '700px' }} className='description text-center mb-4'>
                                        Our program takes pride in bridging academia and industry through open research projects that tackle real-world industrial challenges.                                </p>

                                    <p style={{ maxWidth: '700px' }} className='description text-center mb-4'>
                                        Join us in the pursuit of knowledge, innovation, and impactful contributions to the world. Together, let's push the boundaries of research and create a brighter future through the fusion of academia and industry expertise.
                                    </p>
                                    <p style={{ maxWidth: '700px' }} className='description text-center mb-4'>
                                        Apply for fellowship program by clicking the button below.</p>
                                       <a href="https://forms.gle/7tAjMkS2SQkvmqEg8" target='blank' className="google_form">
                                       <i  style={{marginRight:'5px'}} className="bi bi-pencil-square"></i>
                                        Apply
                                        </a>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Fellowship