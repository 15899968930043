import './App.css';
import Home from './pages/Home';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import { Routes } from 'react-router-dom';
import { Route } from 'react-router-dom';
import  Projects  from './pages/Projects/Projects';
import Notfound from './components/notfound/Notfound';

function App() {
  return (
    <>
      <Header />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/home' element={<Home />} />
        <Route path='/projects' element={<Projects />} />
        <Route path="*" element={<Notfound/>} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
