import React from 'react';
import "./Aboutus.css";
const Aboutus = () => {
    return (
        <>
            <section id="about" className="seo_features_one sec_pad services">
                <div className="container about-section-title" data-aos="fade-up">
                    <h2>About community</h2>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 d-flex align-items-center">
                            <div className="seo_features_content">
                                <p className='description text-center'>
                                    At ChemBioAI, we're dedicated to revolutionizing the fields of Artificial Intelligence (AI) and Life sciences. Our community brings together experts, researchers, and enthusiasts passionate about leveraging cutting-edge technology in the related field. We strive to facilitate open research projects, providing a platform for discussion, connection, and development. Our commitment is to foster a collaborative environment where members share skills and resources, advancing open research practices for collective growth and innovation.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Aboutus