import React from "react";
import Hero from "../components/hero/Hero";
import Aboutus from "../components/about/Aboutus";
import KeyTenets from "../components/keytenets/KeyTenets";
import How from "../components/how/How";
import Vision from "../components/vision/Vision";
import Team from "../components/team/Team";
import Contact from "../components/contact/Contact";

const Home = () => {
  return (
    <>
      <Hero />
      <Aboutus />
      <KeyTenets />
      <How />
      <Vision />
      {/* <Team /> */}
      <Contact />
    </>
  );
};

export default Home;
