import React from 'react'
import how from '../../assets/images/how.png'
import question from '../../assets/images/question.png'
import './How.css'

const How = () => {
    return (
        <>
            <section className="seo_service_area sec_pad" id="why">
                <div className="container">
                <div className="container section-title" data-aos="fade-up">
                    <h2>Why Us?</h2>
                </div>
                    <div className="row flex-row-reverse">
                        <div className="col-lg-6 d-flex align-items-center">
                            <div className="seo_features_img">
                                <img src={question} alt="" className="w70" />
                            </div>
                        </div>
                        <div className="col-lg-6 d-flex align-items-center">
                            <div className="service_details">
                                <p className="f_400 mb_50"></p>
                                <ul className="list-unstyled mb-30">
                                    <li><i className="ti-arrow-circle-right"></i>Investing in one of the best research community</li>
                                    <li><i className="ti-arrow-circle-right"></i>Networking with the best resource people</li>
                                    <li><i className="ti-arrow-circle-right"></i>Chance to contribute for the growing research programs around the world and gain recognition</li>
                                    <li><i className="ti-arrow-circle-right"></i>Open Access to multiple resources and data sets</li>
                                    <li><i className="ti-arrow-circle-right"></i>Career growth</li>
                                    <li><i className="ti-arrow-circle-right"></i>Personal development </li>
                                    <li><i className="ti-arrow-circle-right"></i>Exposure to multi-disciplinary perspectives</li>
                                    <li><i className="ti-arrow-circle-right"></i>Access to mentorship and guidance</li>
                                    <li><i className="ti-arrow-circle-right"></i>Contribute to societal progress</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}

export default How