import React, { useEffect } from 'react';
import './Projects.css';
import Fellowship from '../../components/felloship/Fellowship';
import Internship from '../../components/internship/Internship';
import Research from '../../components/Research/Research';

const Projects = () => {
    useEffect(() => {

        window.scrollTo(0, 0)
        return () => {
        }
    }, [])

    return (
        <>
            <Fellowship />
            <Internship />
            {/* <Research /> */}
        </>
    )
}

export default Projects