import React from 'react';
import './KeyTenets.css';



const KeyTenets = () => {
    return (
        <>
            <section id="services" className="seo_features_one sec_pad services bg-black">
                <div className="container section-title" data-aos="fade-up">
                    <h2>Key Tenets of Our Community</h2>
                </div>
                <div className="container">
                    <div className="row g-5">

                        <div className="col-lg-6 " data-aos="fade-up" data-aos-delay="100">
                            <div className="service-item d-flex">
                                <div>
                                    <h4 className="title"><a className="stretched-link">Networking Opportunities</a></h4>
                                    <p className="description">
                                        Unlock exceptional networking prospects by joining our community, connecting with like-minded individuals and experts from diverse fields to exchange ideas, collaborate on projects, and broaden your perspectives
                                    </p>
                                </div>
                            </div>
                        </div>


                        <div className="col-lg-6 " data-aos="fade-up" data-aos-delay="200">
                            <div className="service-item d-flex">
                                <div>
                                    <h4 className="title"><a className="stretched-link">Innovation for Sustainability</a></h4>
                                    <p className="description">
                                        Dedicated to innovation, our community utilizes cutting-edge science and technology to engineer sustainable solutions, envisioning a brighter and more sustainable future for everyone.                                    </p>

                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 " data-aos="fade-up" data-aos-delay="300">
                            <div className="service-item d-flex">
                                <div>
                                    <h4 className="title"><a className="stretched-link">Open Research Community</a></h4>
                                    <p className="description">
                                        Committed to building a culture of transparency and collaboration, we strive to lead open research in India, making significant contributions to the global scientific community                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 " data-aos="fade-up" data-aos-delay="400">
                            <div className="service-item d-flex">
                                <div>
                                    <h4 className="title"><a className="stretched-link">Synergy of Discipline</a></h4>
                                    <p className="description">
                                        We harness the synergies of chemistry, biology, and artificial intelligence to explore novel interdisciplinary solutions for complex challenges.                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 " data-aos="fade-up" data-aos-delay="500">
                            <div className="service-item d-flex">
                                <div>
                                    <h4 className="title"><a className="stretched-link">Open Research Projects</a></h4>
                                    <p className="description">
                                        Participate in impactful open research projects, transcending traditional boundaries, to enhance your skills and contribute to the collective advancement of science and technology                                        </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 " data-aos="fade-up" data-aos-delay="600">
                            <div className="service-item d-flex">
                                <div>
                                    <h4 className="title"><a className="stretched-link">Benefits of Collaboration</a></h4>
                                    <p className="description">
                                        Engage in cross-disciplinary collaborations within our community, fostering an environment where diverse perspectives unite to address challenges comprehensively                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-12 "  data-aos="fade-up" data-aos-delay="600">
                            <div className="service-item d-flex">
                                <div>
                                    <h4 className="title adjure"><a  className="stretched-link">Empowering the Future</a></h4>
                                    <p  className="description adjure2">
                                        Shape the future through your research, ideas, and collaborations, joining us in making a lasting impact on the world              </p>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

            </section>
        </>
    )
}

export default KeyTenets