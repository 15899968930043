import React from 'react'
import './Contact.css'

const Contact = () => {
  return (
    <>
     <section id="contact" className="seo_features_one sec_pad services bg-black">
                <div className="container about-section-title" data-aos="fade-up">
                    <h2>Contact us</h2>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 d-flex align-items-center">
                            <div style={{display:'block',width:'300px',margin:'auto'}} className="seo_features_content feat">
                                <div  className="service_details mb-30">
                                    <ul  className="list-unstyled mb-30">
                                        <li> <i class="bi bi-envelope"></i> contact@chembioai.org</li>
                                        <li><i class="bi bi-telephone"></i>+91 9498866488</li>
                                        <li style={{lineHeight:'2'}}><i class="bi bi-house-add"></i>B-305, Block II, <br/> KSSIDC Complex, <br/> Electronic City Phase I,<br/> Bengaluru 560100
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    </>
  )
}

export default Contact