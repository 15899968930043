import React from "react";
import banner from "../../assets/images/banner.png";
import "./Hero.css";

const Hero = () => {
  return (
    <>
      <section
        style={{ backgroundColor: "black", marginBottom: "0px" }}
        className="seo_home_area"
        id="home"
      >
        <div class="home_bubble">
          <div class="bubble b_one"></div>
          <div class="bubble b_two"></div>
          <div class="bubble b_three"></div>
          <div class="bubble b_four"></div>
          <div class="bubble b_five"></div>
          <div class="bubble b_six"></div>
          <div class="triangle b_seven" data-parallax='{"x": 20, "y": 150}'>
            <img src="img/seo/triangle_one.png" alt="" />
          </div>
          <div class="triangle b_eight" data-parallax='{"x": 120, "y": -10}'>
            <img src="img/seo/triangle_two.png" alt="" />
          </div>
          <div class="triangle b_nine">
            <img src="img/seo/triangle_three.png" alt="" />
          </div>
        </div>
        <div class="banner_top">
          <div class="container">
            <div class="row">
              <div class="col-md-12 text-center seo_banner_content">
                <h2 class="wow fadeInUp" data-wow-delay="0.3s">
                  CHEMBIOAI
                </h2>
                <p class="wow fadeInUp" data-wow-delay="0.5s">
                  {" "}
                  Pioneering Open Source Research in AI and Life Sciences{" "}
                </p>
                <p className="wow fadeInUp">
                  ChembioAI is a dynamic research open source community,
                  committed to advancing scientific frontiers by seamlessly
                  blending the realms of Chemistry, Biology, and Artificial
                  Intelligence.
                </p>
                <a
                  href="https://discord.gg/nGtFAR3hwn"
                  target="blank"
                  className="btn btn-mod btn-border btn-circle btn-large"
                >
                  <i style={{ fontSize: "25px" }} className="bi bi-discord"></i>
                  <span style={{ marginLeft: "4px", display: "inline-block" }}>
                    Join Discord
                  </span>
                </a>
                <a
                  href="https://chat.whatsapp.com/FmrqjhNAZsgECvLsJcR0fw"
                  className="btn btn-mod btn-border btn-circle btn-large m-2"
                >
                  <i
                    style={{ fontSize: "25px" }}
                    className="bi bi-whatsapp"
                  ></i>
                  <span style={{ marginLeft: "4px", display: "inline-block" }}>
                    Join whatsapp
                  </span>
                </a>
              </div>
            </div>
            <div class="saas_home_img wow fadeInUp" data-wow-delay="0.8s">
              <img src={banner} alt="" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Hero;
