import React from 'react'

const Notfound = () => {
  return (
    <>
    <div className="d-flex align-items-center justify-content-center vh-100 bg-black">
        <h1 className="display-1 fw-bold text-white">404, page not found</h1>
      
    </div>
    </>
  )
}

export default Notfound