import React from 'react'
import logo from '../../assets/images/logo.png';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import "./Header.css"

const Header = () => {

    const caseconverter = (text) => {
        const fintext = text.toUpperCase();
        return fintext
    }

    return (
        <>
            <header className="header_area">
                <nav className=" navbar navbar-expand-lg menu_one">
                    <div className="container custom_container p0">
                        <HashLink className="navbar-brand" to="/#home">
                            <img style={{ width: '160px' }} src={logo} srcSet={`${logo} 2x`} alt="logo" />
                        </HashLink>
                        <button className="navbar-toggler collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            aria-expanded="false" aria-label="Toggle navigation">
                            <span className="menu_toggle">
                                <span className="hamburger">
                                    <span style={{backgroundColor:'white'}}></span>
                                    <span style={{backgroundColor:'white'}}></span>
                                    <span style={{backgroundColor:'white'}}></span>
                                </span>
                                <span className="hamburger-cross">
                                    <span style={{backgroundColor:'white'}}></span>
                                    <span style={{backgroundColor:'white'}}></span>
                                </span>
                            </span>
                        </button>

                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav ms-auto menu">
                                <li className="nav-item">
                                    <HashLink className="nav-link" to="/#home">
                                        {caseconverter('home')}
                                    </HashLink>
                                </li>
                                <li className="nav-item">
                                    <HashLink className="nav-link" to="/#about">
                                        {caseconverter('About us')}
                                    </HashLink>
                                </li>
                                <li className="nav-item">
                                    <HashLink className="nav-link" to="/#why">
                                        {caseconverter('Why us')}
                                    </HashLink>
                                </li>
                                {/* <li className="nav-item">
                                    <HashLink className="nav-link" to="/#vision">
                                        {caseconverter('Vision')}
                                    </HashLink>
                                </li> */}


                                <li className="nav-item">
                                    <Link className="nav-link" to="/projects">
                                        {caseconverter('projects')}
                                    </Link>
                                </li>

                                <li className="nav-item">
                                    <HashLink className="nav-link navlink-prim" to="/#contact">
                                        {caseconverter('contact')}
                                    </HashLink>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link navlink-prim" href="https://github.com/boltzmannlabs/chembioai.org">
                                    <i style={{fontSize:'25px'}} class="bi bi-github"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>

        </>
    )
}

export default Header