import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import './assets/vendors/themify-icon/themify-icons.css';
import "./assets/vendors/elagent/style.css"
import "./assets/vendors/flaticon/flaticon.css"
import "./assets/vendors/owl-carousel/assets/owl.carousel.min.css"
import "./assets/vendors/nice-select/nice-select.css";
import "./assets/vendors/magnify-pop/magnific-popup.css";
import "./assets/vendors/scroll/jquery.mCustomScrollbar.min.css";
import './assets/css/bootstrap.min.css';
import './assets/css/custom.css';
import './assets/css/style.css'
import './assets/css/responsive.css';
import 'bootstrap-icons/font/bootstrap-icons.min.css';
import 'bootstrap/dist/js/bootstrap.bundle'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
    <App />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
