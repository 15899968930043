import React from 'react'
import './Internship.css'

const Internship = () => {
    return (
        <>
            <section id="about" className="seo_features_one sec_pad services">
                <div className="container about-section-title" data-aos="fade-up">
                    <h2>Internships </h2>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 d-flex align-items-center">
                            <div className="seo_features_content">
                                <p className='description text-center mb-4'>
                                    Our internship program offers a unique opportunity for both undergraduate and master's students to engage in hands-on learning. Participants will work on impactful open-source projects addressing real-world industrial challenges, gaining valuable experience. With mentorship opportunities, successful completion leads to certification and personalized recommendation letters, providing a comprehensive and enriching experience.
                                </p>

                                <div className="service_details">
                                   
                                    <ul className="list-unstyled mb-30">
                                        <li><i className="ti-arrow-circle-right"></i>Open to undergraduates and master’s students</li>
                                        <li><i className="ti-arrow-circle-right"></i>Involves working on an open source project that tackles real-world industrial challenges.</li>
                                        <li><i className="ti-arrow-circle-right"></i>Mentorship Opportunities
                                        </li>
                                        <li><i className="ti-arrow-circle-right"></i>Certification on completion
                                        </li>
                                        <li><i className="ti-arrow-circle-right"></i>Recommendation letters
                                        </li>
                                    </ul>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Internship