import React from 'react'
import vision2 from '../../assets/images/vision2.png'
import mission2 from '../../assets/images/mission2.png'

const Vision = () => {
    return (
        <>
            <section className="seo_features_one sec_pad bg-black" id="vision">
                <div className="container">
                    <div className="row flex-row-reverse">
                        <div className="col-lg-6">
                            <div className="seo_features_img">
                                <img src={vision2} alt="" className="w50" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="seo_features_content">
                                <h4 className='text-white'>Vision</h4>
                                <p>
                                    Our vision is to emerge as one of the top open research communities in India, embracing the expansive horizons of chemistry, biology, and AI. Also to be recognized for our unwavering commitment to fostering collaboration, innovation, and the advancement of open research practices. We aspire to lead the way in creating a dynamic and influential community that significantly contributes to the research landscape in India and beyond.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-lg-6">
                            <div className="seo_features_img seo_features_img_two">
                                <img src={mission2} alt="" className="w50" />
                            </div>
                        </div>
                        <div className="col-lg-6 d-flex align-items-center">
                            <div className="seo_features_content">
                                <h4 className='text-white'>mission</h4>
                                <p>We are on a mission to create inspiring and educational content for investors and deep tech founders. We aim to focus on community, inspiration, networking and growing together. We aim to help them to come together, brainstorm and grow together with support.</p>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Vision